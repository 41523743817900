var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"2"}},[_c('h3',[_c('i',{staticClass:"fas fa-users-medical"}),_vm._v(" เพิ่มพันธมิตร ")])]),_c('b-col',{attrs:{"md":"3"}}),_c('b-col',{attrs:{"cols":"12","md":"6"}}),_c('b-col',{staticClass:"p-0",staticStyle:{"margin-top":"20px"},attrs:{"md":"1"}})],1)],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ชื่อ","label-for":"customDelimiter"}},[_c('validation-provider',{attrs:{"name":"ชื่อ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"floating-label2","state":errors.length > 0 ? false : null,"type":"text","placeholder":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกชื่อ" : ''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"นามสกุล","label-for":"customDelimiter"}},[_c('validation-provider',{attrs:{"name":"นามสกุล","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"floating-label2","state":errors.length > 0 ? false : null,"type":"text","placeholder":""},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกนามสกุล" : ''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"เบอร์โทรศัพท์","label-for":"customDelimiter"}},[_c('validation-provider',{attrs:{"name":"เบอร์โทรศัพท์","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"floating-label2","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"10","state":errors.length > 0 ? false : null,"type":"number","placeholder":""},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกเบอร์โทรศัพท์" : ''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"รหัสผ่าน","label-for":"customDelimiter"}},[_c('validation-provider',{attrs:{"name":"รหัสผ่าน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"floating-label2","state":errors.length > 0 ? false : null,"type":"text","placeholder":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกรหัสผ่าน" : ''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ธนาคาร","label-for":"customDelimiter"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.banklist,"reduce":function (name) { return name.bankcode; }},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"เลขที่บัญชี","label-for":"customDelimiter"}},[_c('b-form-input',{attrs:{"id":"floating-label2","type":"number","placeholder":""},model:{value:(_vm.acc_no),callback:function ($$v) {_vm.acc_no=$$v},expression:"acc_no"}})],1)],1),_c('b-col',{staticClass:"card-warning",attrs:{"md":"12"}},[_c('div',{staticClass:"mt-1"},[_c('b-form-group',{attrs:{"label":"Merchant เอเย่นต์","label-for":"Merchant"}},[_c('validation-provider',{attrs:{"name":"Merchant เอเย่นต์","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-white",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"merchant_id","options":_vm.merchant_list,"state":errors.length > 0 ? false : null},model:{value:(_vm.merchant_data),callback:function ($$v) {_vm.merchant_data=$$v},expression:"merchant_data"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณาเลือกMerchant เอเย่นต์" : ''))])]}}])})],1),_c('b-form-group',{attrs:{"label":"% ที่ให้(สำหรับพันธมิตร) (หากกำหนดตรงนี้เป็น 0 จะใช้ค่าที่กำหนดไว้ในระบบ)","label-for":"percent"}},[_c('b-form-input',{attrs:{"id":"percent","type":"number","placeholder":""},model:{value:(_vm.zean_percent),callback:function ($$v) {_vm.zean_percent=$$v},expression:"zean_percent"}})],1)],1)]),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('hr'),_c('b-form-group',[_c('b-button',{attrs:{"block":"","variant":"gradient-dark"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('i',{staticClass:"fad fa-cloud-download"}),_vm._v(" บันทึกข้อมูล ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }